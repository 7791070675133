<script setup lang="ts">
import { load } from '@fingerprintjs/fingerprintjs';
import { onMounted } from 'vue';
import { getProject, getSimilarProject, logView } from '~/api/projects';
import type { Project } from '~/api/types';
import ProjectMainAdv from '~/components/pages/projects/ProjectMainAdv.vue';
import ProjectMainBottom from '~/components/pages/projects/ProjectMainBottom.vue';
import NewProjectMainBottom from '~/components/pages/projects/NewProjectMainBottom.vue';
import ProjectMainDownload from '~/components/pages/projects/ProjectMainDownload.vue';
import ProjectMainTop from '~/components/pages/projects/ProjectMainTop.vue';
import ProjectPageSide from '~/components/pages/projects/ProjectPageSide.vue';
import SimilarProjects from '~/components/pages/projects/SimilarProjects.vue';
import VDivider from '~/components/VDivider.vue';
import VLoader from '~/components/VLoader.vue';
import { useUserStore } from '~/stores/UserStore';

const userStore = useUserStore();
const analyticsStore = useAnalyticsStore();

const route = useRoute();

const { data: project, refresh } = await useAsyncData<Project>('project', () => getProject(route.params.id!.toString()), {
  watch: [() => userStore.user],
});
const { data: similarProjectsData } = await useAsyncData<Project[]>(
  'similar_project',
  () => getSimilarProject(project.value!.id.toString()),
  {
    server: false,
    lazy: true,
    default: () => [],
  }
);

const timerInterval = ref();

onMounted(async () => {
  if (project.value?.long_poll) {
    timerInterval.value = setInterval(async () => {
      if (project.value?.long_poll) {
        await refresh();
      } else {
        clearInterval(timerInterval.value);
      }
    }, 5000);
  }
  if (window && 'yaCounter95948387' in window) {
    const setFp = async () => {
      const fp = await load();
      const { visitorId } = await fp.get();
      await logView('view', project.value?.id?.toString() || null, window?.yaCounter95948387?.getClientID() || null, visitorId);
    };

    await setFp();
  }
  analyticsStore.openProjectPage(project.value?.id || 0);
});
onBeforeUnmount(() => {
  clearInterval(timerInterval.value);
});

definePageMeta({
  middleware: ['slug'],
});

if (project.value?.status) {
  const title = ref(project.value.title + ' | ' + (project.value.idea || 'Нейросеть Бегемот'));
  const description = ref(project.value.description);
  const meta = [
    {
      name: 'title',
      content: title.value,
    },
    {
      hid: 'description',
      name: 'description',
      content: description.value,
    },

    {
      property: 'og:title',
      content: title.value,
    },
    {
      property: 'og:description',
      content: description.value,
    },
    {
      property: 'og:type',
      content: 'article',
    },
    {
      property: 'og:url',
      content: project.value.url,
    },
    {
      itemprop: 'learningResourceType',
      content: 'article',
    },
  ];

  if (project.value?.picture_url) {
    meta.push({
      property: 'og:image',
      content: project.value.picture_url,
    });
  }
  if (project.value?.no_index) {
    meta.push({
      name: 'robots',
      content: 'noindex',
    });
  }
  useHead({
    meta,
    title: title.value,
  });
} else {
  useHead({
    title: project.value?.prompt,
    meta: [
      {
        name: 'description',
        content: project.value?.prompt,
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  });
}

watch(
  project,
  (value) => {
    if (value?.is_owner && !value.payed_status) useTelegramPopupStore().isHidden = true;
    else useTelegramPopupStore().isHidden = false;
  },
  { immediate: true }
);

watch(
  () => project.value?.status,
  (newStatus, oldStatus) => {
    if (!project.value) return;
    if (newStatus === 0 && oldStatus === undefined) analyticsStore.generationStart(project.value.id);
    if (oldStatus === 0 && newStatus === 1) analyticsStore.generationEnd(project.value.id);
  },
  { immediate: true }
);

if (!project.value) throw createError({ statusCode: 404 });
</script>

<template>
  <div
    v-if="project && project.status == 1"
    class="project-page"
  >
    <div class="container">
      <div class="project-page__body">
        <div
          class="project-page__main"
          :class="{ essay: project.entity === 'final-essay' }"
        >
          <project-main-top :project="project" />
          <v-divider
            v-if="project.entity != 'final-essay'"
            is-gray
          />
          <project-main-download
            v-if="project?.is_owner && !project?.long_poll && project?.download_available"
            :project="project"
          />
          <template v-if="project?.paragraphs?.length">
            <new-project-main-bottom
              v-if="project?.is_chapters_displayed"
              :paragraphs="project?.paragraphs"
            />
            <project-main-bottom
              v-else
              :chapters="project?.paragraphs"
            />
          </template>
          <project-main-adv
            v-if="!project?.payed_status"
            :project="project"
          />
        </div>
        <project-page-side :project="project" />
      </div>
      <v-divider
        v-if="similarProjectsData.length"
        is-gray
      />
      <similar-projects
        v-if="similarProjectsData.length"
        :similar-projects="similarProjectsData"
      />
    </div>
  </div>
  <v-loader
    v-else
    :data="project?.display_status"
  />
</template>

<style lang="scss">
.project-page {
  margin-top: 60px;
  padding-top: 39px;
  @include media-breakpoint-down(md) {
    margin-top: 55px;
    padding-top: 24px;
    overflow: hidden;
  }
}

.project-page__body {
  margin-bottom: 128px;
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 16px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: 100%;
    gap: 32px;
    margin-bottom: 32px;
  }

  .project-page__main {
    display: flex;
    padding: 32px;
    background: white;
    border-radius: 32px;
    flex-direction: column;
    gap: 32px;
    @include media-breakpoint-down(md) {
      gap: 24px;
      padding: 24px 16px;
    }
  }

  .essay {
    gap: 16px;
    @include media-breakpoint-down(md) {
      gap: 8px;
    }
  }
}
</style>
