<script setup lang="ts">
import { Download, Lock, Sparkles } from 'lucide-vue-next';
import type { EntityType, Project } from '~/api/types.ts';
import PageSideCard from '~/components/cards/PageSideCard.vue';
import LoadingDots from '~/components/LoadingDots.vue';
import ActionBlock from '~/components/pages/projects/ActionBlock.vue';
import RateBottomSheet from '~/components/rating/RateBottomSheet.vue';
import RateModal from '~/components/rating/RateModal.vue';
import { entities } from '~/enitityHelper';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import VButton from '~/components/VButton.vue';
import OtzovikModal from '~/components/rating/StarRating/OtzovikModal.vue';

interface Props {
  project: Project;
}
const route = useRoute();
const props = defineProps<Props>();

const texts = entities[(props.project.entity as EntityType) || 'project'];
const showRateModal = ref(props.project.is_owner && !props.project.rated_dt && route.query.rating === null);
const analyticsStore = useAnalyticsStore();
const isOtzovikOpen = ref<boolean>(false);
</script>

<template>
  <div class="project-page__side">
    <div class="project-page__side_sticky">
      <page-side-card
        v-if="props.project.payed_status && props.project.is_owner"
        :text="props.project.text_status ? texts.downloadCard.description : texts.isGeneratingCard.description"
        :title="props.project.text_status ? texts.downloadCard.title : texts.isGeneratingCard.title"
      >
        <div class="download-buttons">
          <a
            v-if="props.project.download_link && props.project.text_status"
            v-tooltip="'Скачать в Word'"
            :href="props.project.download_link"
            class="new-button new-button_md new-button_secondary w-full animation-scale"
            target="_blank"
            rel="noopener noreferrer"
            @click="analyticsStore.downloadText(props.project.id)"
          >
            <Download
              width="20"
              height="20"
            />
            <span>DOCX</span>
          </a>
          <button
            v-else-if="!props.project.text_status"
            v-tooltip="'Файл генерируется'"
            disabled
            class="new-button new-button_md new-button_secondary w-full"
          >
            <LoadingDots />
            <span>DOCX</span>
          </button>
          <a
            v-if="props.project.download_link_pdf && props.project.text_status"
            v-tooltip="'Скачать в PDF'"
            :href="props.project.download_link_pdf"
            class="new-button new-button_md new-button_secondary w-full animation-scale"
            target="_blank"
            rel="noopener noreferrer"
            @click="analyticsStore.downloadText(props.project.id)"
          >
            <Download
              width="20"
              height="20"
            />
            <span>PDF</span>
          </a>
          <button
            v-else-if="!props.project.text_status"
            v-tooltip="'Файл генерируется'"
            disabled
            class="new-button new-button_md new-button_secondary w-full"
          >
            <LoadingDots />
            <span>PDF</span>
          </button>
          <a
            v-if="props.project.download_link_pptx && props.project.presentation_status === 1"
            v-tooltip="'Скачать в PowerPoint'"
            :href="props.project.download_link_pptx!"
            class="new-button new-button_md new-button_secondary w-full animation-scale"
            target="_blank"
            rel="noopener noreferrer"
            @click="analyticsStore.downloadSlides(props.project.id)"
          >
            <Download
              width="20"
              height="20"
            />
            <span>PPTX</span>
          </a>
          <button
            v-else-if="props.project.presentation_status === 0"
            v-tooltip="'Файл генерируется'"
            disabled
            class="new-button new-button_md new-button_secondary w-full"
          >
            <LoadingDots />
            <span>PPTX</span>
          </button>
          <button
            v-else
            v-tooltip="'Презентация будет доступна после оплаты'"
            disabled
            class="new-button new-button_md new-button_secondary w-full"
          >
            <Lock
              width="20"
              height="20"
            />
            <span>PPTX</span>
          </button>
        </div>
      </page-side-card>

      <action-block
        v-if="props.project && props.project.action_block"
        :project="props.project"
      >
      </action-block>
      <page-side-card
        v-if="props.project && props.project.payed_status && props.project.is_owner && !props.project.long_poll && !props.project.rated_dt"
        :title="texts.rateCard.title"
        :text="texts.rateCard.description"
      >
        <v-button
          class="w-100 animation-scale"
          schema="secondary"
          @click="showRateModal = true"
        >
          Оценить
        </v-button>
      </page-side-card>

      <page-side-card
        v-if="props.project.is_owner && props.project.rating && props.project.rating >= 4 && props.project.entity != 'final-essay'"
        title="Вернем деньги за проект"
        text="Оставьте отзыв на Отзовик.ру, и мы вернем вам полную стоимость проекта!"
      >
        <v-button
          schema="secondary"
          @click="isOtzovikOpen = true"
          >Вернуть деньги</v-button
        >
      </page-side-card>
      <page-side-card
        :title="texts.otherProjectCard.title"
        :text="texts.otherProjectCard.description"
      >
        <v-button
          class="w-100 animation-scale"
          :schema="
            props.project && props.project.payed_status && props.project.is_owner && !props.project.long_poll ? 'primary' : 'secondary'
          "
          @click="navigateTo('/')"
        >
          Создать
          <template #iconLeft>
            <Sparkles :size="20" />
          </template>
        </v-button>
      </page-side-card>
    </div>
  </div>
  <rate-modal
    v-if="showRateModal"
    :project="props.project"
    @close="showRateModal = false"
  />
  <otzovik-modal
    :project="props.project"
    :is-open-by-click="isOtzovikOpen"
    @close="isOtzovikOpen = false"
  />
  <rate-bottom-sheet
    :project="props.project"
    :is-open-by-click="showRateModal"
    @closed-bottom-sheet="showRateModal = false"
  />
</template>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.project-page__side_sticky {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: sticky;
  top: 96px;

  .download-buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .download-button {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 12px;
      padding: 8px 24px;
      // background: $background-theme-fade;
      height: 56px;
      outline: none;
      border: none;
      text-align: start;
      color: $foreground-theme;
      background: $background-theme-fade;
      border-radius: 12px;
      transition: all 300ms;

      > span {
        width: 100%;
      }

      &:hover {
        background: rgba($foreground-theme, 0.1);
      }

      &:disabled {
        opacity: 0.7;
        user-select: none;

        &:hover {
          background: $background-theme-fade;
        }
      }
    }
  }
}

.muted {
  opacity: 70%;
  &:hover {
    cursor: default;
  }
}
</style>
