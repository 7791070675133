<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core';
import type { PaymentType } from '~/api/projects';
import { copyProjectById, getActionBlock, getPaymentLink } from '~/api/projects';
import type { ActionBlock, PayItem, Project, Service } from '~/api/types';
import PageSideCard from '~/components/cards/PageSideCard.vue';
import VCollapse from '~/components/common/VCollapse.vue';
import VSwitch from '~/components/common/VSwitch.vue';
import VDivider from '~/components/VDivider.vue';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import { useProjectStore } from '~/stores/ProjectStore';
import { useUserStore } from '~/stores/UserStore';
import { useAuthStore } from '~/stores/AuthStore';
import { useJivoStore } from '~/stores/JivoStore';
import { sendEvents } from '~/api/analytics';
import * as Sentry from '@sentry/vue';
import VModal from '~/components/common/VModal.vue';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import VButton from '~/components/VButton.vue';
import BlackFridayTimer from '~/components/promo/black-friday/BlackFridayTimer.vue';
import PromocodeCard from '~/components/pages/projects/PromocodeCard.vue';

const props = defineProps<{ project: Project }>();
const authStore = useAuthStore();
const jivoStore = useJivoStore();
const analyticsStore = useAnalyticsStore();
const switchValue = ref<Service[]>(
  props.project.action_block.items.filter((item) => item.optional && item.selected).map((item) => item.service)
);
const loading = ref(false);
const stripeLoading = ref(false);
const userStore = useUserStore();
const link = ref(props.project.action_block.link || '');
const cart = ref<PayItem[]>([...props.project.action_block.items.filter((item: PayItem) => !item.optional || item.selected)]);
const actionBlockRef = ref<HTMLDivElement>(null);
const actionBlockRefIsVisible = useElementVisibility(actionBlockRef);
const projectsStore = useProjectStore();
const isCollapseOpen = ref(props.project.action_block.items.filter((v) => v.optional).map(() => false));

watchSyncEffect(() => {
  cart.value = [...props.project.action_block.items.filter((item: PayItem) => !item.optional || item.selected)];
  switchValue.value = props.project.action_block.items.filter((item) => item.optional && item.selected).map((item) => item.service);
});

onMounted(() => {
  jivoStore.bottomOffset = 80;
});

watch(actionBlockRefIsVisible, (value) => {
  if (value) jivoStore.resetLayout();
  else jivoStore.bottomOffset = 80;
});

const basePrice = computed(() => props.project.action_block.items.filter((v) => !v.optional).reduce((a, b) => a + b.price, 0));
const discountBasePrice = computed(() =>
  props.project.action_block.items.filter((v) => !v.optional).reduce((a, b) => a + (b.discount_price ?? b.price), 0)
);
const defaultPrice = computed(() => cart.value.reduce((sum: number, item: PayItem) => sum + item.price, 0));
const discountPrice = computed(() => cart.value.reduce((sum: number, item: PayItem) => sum + (item.discount_price ?? item.price), 0));

type LinkType = 'stripe' | 'tinkoff';
const error = ref<boolean>(false);
const errorBottomSheet = ref<InstanceType<typeof VueBottomSheet>>();
watch(error, (value) => {
  if (value) errorBottomSheet.value.open();
  else errorBottomSheet.value.close();
});
const onErrorClose = () => {
  if (errorBottomSheet.value) errorBottomSheet.value.close();
  error.value = false;
};

const getPaymentLinkFromCart = async (paymentType: PaymentType) => {
  const services = cart.value.map((i) => i.service);
  const projectId = props.project.id.toString();
  const promocode = props.project.action_block.promocode?.code || undefined;

  return await getPaymentLink(projectId, services, paymentType, promocode);
};

const getStripeLink = async () => {
  stripeLoading.value = true;
  const { payment_link } = await getPaymentLinkFromCart('en');
  stripeLoading.value = false;
  return payment_link;
};

const getTinkoffLink = async () => {
  loading.value = true;
  const { payment_link } = await getPaymentLinkFromCart('ru');
  loading.value = false;
  return payment_link;
};

const navigateToPayment = async (linkType: LinkType) => {
  if (linkType === 'stripe') {
    const stripeLink = await getStripeLink();
    if (!stripeLink) throw new Error('Null Stripe link in response');
    return navigateTo(stripeLink, { external: true });
  }
  if (linkType === 'tinkoff') {
    const tinkoffLink = await getTinkoffLink();
    if (!tinkoffLink) throw new Error('Null Tinkoff link in response');
    return navigateTo(tinkoffLink, { external: true });
  }
};

const onPaymentSubmit = async (linkType: LinkType) => {
  await sendEvents([
    {
      event_name: 'submitPayForm',
      project_id: props.project.id,
      amount: discountPrice.value,
      services: cart.value.map((i) => i.service),
      ts: new Date().getTime(),
    },
  ]);

  await navigateToPayment(linkType).catch((err) => {
    Sentry.captureException(err);
    refreshNuxtData('project');
    error.value = true;
  });
};

const changeCart = async (item: PayItem) => {
  if (!item.optional) return;

  const isInCart = cart.value.map((i: PayItem) => i.service).includes(item.service);

  analyticsStore.toggleServices(
    props.project.id,
    cart.value.map((i: PayItem) => i.service)
  );
  if (isInCart) analyticsStore.disableService(props.project.id, item.service);
  else analyticsStore.enableService(props.project.id, item.service);

  const newCart = isInCart ? cart.value.filter((i: PayItem) => i.service !== item.service) : [...cart.value, item];

  if (props.project.action_block.promocode && props.project.action_block.promocode.unit != 'percent') {
    updateActionBlock(
      await getActionBlock({
        promocode: props.project.action_block.promocode.code,
        projectId: props.project.id,
        services: newCart.map((item) => item.service),
      })
    );
  } else {
    cart.value = newCart;
  }
};

onMounted(() => {
  setTimeout(() => {
    if (projectsStore.isCopied && actionBlockRef?.value) {
      actionBlockRef?.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      projectsStore.isCopied = false;
    }
  }, 1000);
});

const copyProject = async () => {
  if (userStore.user) {
    loading.value = true;
    await copyProjectById(props.project.id.toString()).then(function (res: Project) {
      projectsStore.isCopied = true;
      navigateTo(`/projects/${res.id}`);
    });
    loading.value = false;
  } else {
    willCopyAfterLogin.value = true;
    authStore.showAuth = true;
  }
};

const willCopyAfterLogin = useCookie<boolean>('willCopyAfterLogin', { maxAge: 60, default: () => false });
watch(
  () => userStore.user,
  (userValue) => {
    if (userValue && willCopyAfterLogin.value && props.project?.id) {
      willCopyAfterLogin.value = false;
      copyProject();
    }
  },
  { immediate: true }
);

const updateActionBlock = (newActionBlock: ActionBlock) => {
  props.project.action_block = newActionBlock;
};

const promocodeUnit = computed(() => props.project.action_block.promocode?.unit);
</script>

<template>
  <black-friday-timer v-if="props.project.action_block.type === 'link' && props.project.action_block.promocode?.code === 'FRIDAY'" />
  <div
    v-if="props.project.action_block.type == 'link'"
    ref="actionBlockRef"
    :key="props.project.action_block.buttonText"
    class="action-block"
  >
    <div class="action-block__heading">
      <div
        v-if="props.project.action_block.items.find((v) => !v.optional)"
        class="action-block__heading__price-and-icon"
      >
        <NuxtImg
          src="/images/icons/zap.svg"
          alt="Zap"
          width="32"
          height="32"
        />
        <div
          v-if="props.project.action_block.items.find((v) => !v.optional)"
          style="display: flex; flex-direction: row; gap: 8px; align-items: end"
        >
          <span
            v-if="discountBasePrice < basePrice && promocodeUnit != 'money'"
            class="action-block__heading__price-and-icon__base-price"
            style="text-decoration: line-through; font-size: 24px; color: #b2b2b2"
            >{{ basePrice }} ₽
          </span>
          <span
            class="action-block__heading__price-and-icon__base-price"
            style="line-height: 110%"
            >{{ promocodeUnit === 'money' ? basePrice : discountBasePrice ?? basePrice }} ₽</span
          >
        </div>
      </div>
      <div class="action-block__heading__type">
        {{ props.project.action_block.title }}
      </div>
    </div>
    <template v-if="props.project.action_block.features.length">
      <ul class="action-block__list">
        <li
          v-for="(feature, idx) in props.project.action_block.features"
          :key="idx"
        >
          <NuxtImg
            src="/images/new/project-advantages/unique.svg"
            :alt="feature"
            width="24"
            height="24"
          />
          <span>
            {{ feature }}
          </span>
        </li>
      </ul>
    </template>
    <v-divider is-gray />
    <div class="action-block__body">
      <div class="action-block__select-group">
        <template
          v-for="(item, i) in props.project.action_block.items.filter((v) => v.optional)"
          :key="item.service"
        >
          <v-collapse
            class="action-block__select-group__collapse"
            caret-position="left"
            caret-type="chevron"
            :is-open="isCollapseOpen[i]"
            @click="
              () => {
                if (!isCollapseOpen[i]) analyticsStore.openServiceDescription(item.service, props.project.id);
                isCollapseOpen = isCollapseOpen.map((v, idx) => (i === idx ? !v : false));
              }
            "
          >
            <template #title>
              <div class="action-block__select-group__collapse__title">
                <p style="text-wrap: nowrap; letter-spacing: -0.01rem; font-weight: 600">
                  {{ item.title }}
                </p>
                <div style="display: flex; gap: 16px; text-wrap: nowrap">
                  <span style="display: flex; gap: 6px">
                    <span
                      v-if="item.discount_price < item.price && promocodeUnit != 'money'"
                      class="action-block__select-item-price"
                      style="color: #8c8c8c; text-decoration: line-through"
                      >{{ item.price }}&nbsp;₽</span
                    >
                    <span class="action-block__select-item-price"
                      >{{ promocodeUnit === 'money' ? item.price : item.discount_price ?? item.price }}&nbsp;₽</span
                    >
                  </span>
                  <v-switch
                    v-model="switchValue"
                    :value="item.service"
                    :disabled="loading"
                    @click.stop
                    @update:model-value="changeCart(item)"
                  />
                </div>
              </div>
            </template>
            <template
              v-if="item.subtitle"
              #content
            >
              <div class="action-block__select-group__collapse__content">{{ item.subtitle }}</div>
            </template>
          </v-collapse>
        </template>
      </div>
    </div>
    <div v-if="defaultPrice">
      <v-button
        schema="primary"
        class="new-button new-button_md new-button_primary animation-scale w-100"
        :class="{ 'new-button_disabled': loading }"
        @click="onPaymentSubmit('tinkoff')"
      >
        <span
          v-if="loading"
          class="new-loader"
        />
        {{ props.project.action_block.buttonText || 'Оплатить' }}
        <span
          :style="{
            opacity: discountPrice < defaultPrice ? 0.3 : 1,
            'text-decoration': discountPrice < defaultPrice ? 'line-through' : 'none',
          }"
        >
          {{ defaultPrice }}&nbsp;₽
        </span>
        <span v-if="discountPrice < defaultPrice"> {{ discountPrice }}&nbsp;₽ </span>
      </v-button>
      <v-button
        schema="secondary"
        class="new-button new-button_secondary animation-scale foreign-button w-100 mt-2"
        :class="{ 'new-button_disabled': stripeLoading }"
        @click="onPaymentSubmit('stripe')"
      >
        <span
          v-if="stripeLoading"
          class="new-loader"
        />
        Оплата зарубежной картой
      </v-button>
    </div>
  </div>

  <div v-else>
    <page-side-card
      class="action-block"
      :title="props.project.action_block.title"
    >
      <ul class="action-block__list">
        <li
          v-for="(feature, i) in props.project.action_block.features"
          :key="i"
        >
          <NuxtImg
            src="/images/new/project-advantages/unique.svg"
            :alt="feature"
            width="24"
            height="24"
          />
          <span class="action-block__list__feature">
            {{ feature }}
          </span>
        </li>
      </ul>
      <div ref="actionBlockRef">
        <v-button
          class="animation-scale"
          schema="primary"
          :disabled="loading"
          style="width: 100%"
          @click="copyProject()"
        >
          {{ props.project.action_block.buttonText || 'Оплатить' }}
        </v-button>
      </div>
    </page-side-card>
  </div>
  <div
    v-if="props.project.action_block && !actionBlockRefIsVisible"
    class="action-block_fixed"
    @click="
      () => {
        actionBlockRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        analyticsStore.clickActionFloatBlock(props.project.id);
      }
    "
  >
    <div class="action-block_fixed__text">
      <span class="action-block_fixed__text__heading">{{ props.project.action_block.title }}</span>
      <span class="action-block_fixed__text__description">{{ props.project.action_block.short_description }}</span>
    </div>
    <v-button
      schema="primary"
      :disabled="loading"
      small
      style="white-space: nowrap"
    >
      {{ props.project.action_block.buttonText || 'Купить' }}
    </v-button>
  </div>
  <promocode-card
    :project="props.project"
    :services="cart.map((item) => item.service)"
    @update-action-block="updateActionBlock"
  />
  <client-only>
    <v-modal
      v-if="error"
      title="Возникла ошибка с оплатой"
      @close="onErrorClose"
    >
      <div class="action-block__error">
        <span class="action-block__error-text"> Пожалуйста, попробуйте оплатить проект снова через несколько минут. </span>
        <v-button
          schema="secondary"
          @click="
            () => {
              onErrorClose();
              jivoStore.openChat();
            }
          "
          >Написать в поддержку</v-button
        >
      </div>
    </v-modal>
    <vue-bottom-sheet
      ref="errorBottomSheet"
      @closed="onErrorClose"
    >
      <div
        style="padding: 32px 16px"
        class="action-block__error"
      >
        <h2 style="margin: 0">Возникла ошибка с оплатой</h2>
        <span class="action-block__error-text">Пожалуйста, попробуйте оплатить снова через несколько минут.</span>
        <v-button
          schema="secondary"
          @click="
            () => {
              onErrorClose();
              jivoStore.openChat();
            }
          "
          >Написать в поддержку</v-button
        >
      </div>
    </vue-bottom-sheet>
  </client-only>
</template>

<style scoped lang="scss">
.action-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  background: white;

  .foreign-button {
    &:not(:hover) {
      background: white !important;
    }
  }

  .new-button {
    font-weight: 500;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__type {
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      width: 70%;
    }

    &__price-and-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__base-price {
        font-size: 36px;
        font-weight: 500;
        line-height: 130%;
        color: $foreground-theme;
      }
    }
  }

  &__header {
    h5 {
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 0;
      color: #5047e6a3;
    }
  }

  &__select-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__collapse {
      background: $background-theme-fade;
      border-radius: 12px;
      padding: 12px;

      &__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        font-size: 14px;
      }

      &__content {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $foreground-gray;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  &__select-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px 12px;
    border-radius: 12px;
    background: white;
  }

  &__select-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    gap: 8px;

    p {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
    }
  }

  &__select-item-price {
    color: $foreground-theme;
    font-weight: 600;
  }

  &__select-item-body {
    color: $foreground-gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      color: $foreground-contrast;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      gap: 8px;
      margin: 0;
      padding: 0;

      svg {
        opacity: 0.7;
      }
    }
  }

  &_fixed {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 50%;
    padding: 16px 20px;
    transform: translateX(-50%);
    width: 100%;
    display: none;
    font-size: 14px;
    font-weight: 500;

    background: white;
    box-shadow: $new-shadow;
    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      box-shadow: 0 -4px 24px rgba(#00000017, 0.08);
    }

    border-radius: 32px 32px 0 0;

    .action-block_fixed__text {
      display: flex;

      flex-direction: column;
      gap: 4px;

      &__heading {
        color: $foreground-contrast;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }

      &__description {
        color: $foreground-theme;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;

        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.action-block__error {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: -8px;

  .action-block__error-text {
    color: $foreground-gray;
    font-size: 16px;
    text-wrap: pretty;
  }
}
</style>
