<script setup lang="ts">
import ActionBlockTimer from '~/components/promo/ActionBlockTimer.vue';
import { blackFridayEndDate, isBlackFridayNow } from '~/constants/black-friday';

const startDate = '2024-11-11T00:00:00';
const targetDate = blackFridayEndDate;
</script>

<template>
  <action-block-timer
    v-if="isBlackFridayNow"
    :start-date="startDate"
    :target-date="targetDate"
    :days="true"
    :hours="true"
    :minutes="true"
    :seconds="true"
  />
</template>
